<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="门店区域">
      <el-cascader
        ref="casRef"
        v-model="data.regionCode"
        :options="areaOptions"
        clearable
        filterable
        collapse-tags
        placeholder="请选择门店区域"
        collapse-tags-tooltip
        @change="changeArea()"
        :show-all-levels="false"
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCode"
        :options="data.shops"
        filterable
        placeholder="请选择门店"
        class="search-select userSelect"
        multiple
        collapse-tags
        style="width: 300px;"
        collapse-tags-tooltip
      />
    </el-form-item>

    <!-- <el-form-item label="收银系统状态">
      <el-select v-model="data.storeStatus" placeholder="请选择收银系统状态" clearable>
        <el-option label="在线" :value="1" />
        <el-option label="未知" :value="2" />
      </el-select>
    </el-form-item>
    <el-form-item label="包厢状态">
      <el-select v-model="data.roomStatus" placeholder="请选择包厢状态" clearable>
        <el-option label="开台" :value="1" />
        <el-option label="关台" :value="0" />
        <el-option label="异常" :value="-1" />
      </el-select>
    </el-form-item> -->
    <el-form-item label="包厢号">
      <el-input placeholder="请输入包厢号" clearable v-model="data.roomName"></el-input>
    </el-form-item>
    <el-form-item label="最近上报时间">
      <el-date-picker
        v-model="data.time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
      />
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists" v-if="data.authMenus.query">
        查询
      </el-button>
      <el-button @click="reset" v-if="data.authMenus.query">
        重置
      </el-button>
      <el-button @click="addProduct" v-if="data.authMenus.batchUploadState"> 批量上报状态 </el-button>
      <el-button @click="exportTable" v-if="data.authMenus.export"> 导出 </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    :highlight-current-row="true"
    :default-expand-all="false"
    v-loading.fullscreen.lock="data.loading"
    :element-loading-text="loadingText"
    row-key="storeCode"
    :expand-row-keys="expands"
  >
    <el-table-column type="expand">
      <template #default="props">
        <el-table :data="props.row.checkStatusVOList" style="width: 100%">
          <el-table-column type="index" label="序号" align="center" width="100" />
          <el-table-column show-overflow-tooltip label="包厢类型" prop="roomTypeName" align="center" />
          <el-table-column show-overflow-tooltip label="包厢号" prop="roomName" align="center" />
          <el-table-column show-overflow-tooltip label="机顶盒mac地址" prop="roomMacKey" align="center" />
          <el-table-column show-overflow-tooltip label="机顶盒IP" prop="ip" align="center" />
          <el-table-column show-overflow-tooltip label="支持剧本杀" align="center">
            <template #default="scope">
              {{ scope.row.isScripted === 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="包厢状态" align="center">
            <template #default="scope">
              <el-tag v-if="scope.row.roomStatus === 1" type="success">开台</el-tag>
              <el-tag v-if="scope.row.roomStatus === 0" type="info">关台</el-tag>
              <el-tag
                v-if="scope.row.roomStatus === -1"
                type="error"
                @click="handleShowError(scope.row.statusMsg)"
                style="cursor: pointer;"
                >异常</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="最近上报时间" prop="uploadTime" align="center" />

          <el-table-column align="center" label="操作" width="400" fixed="right">
            <template #default="scope">
              <el-button type="text" @click="specOperation(1, scope.row, 'box')" v-if="data.authMenus.uploadState"
                >上报状态</el-button
              >
              <el-button type="text" @click="specOperation(2, scope.row, 'box')" v-if="data.authMenus.syncRoom"
                >同步房台</el-button
              >
              <el-button type="text" @click="specOperation(3, scope.row, 'box')" v-if="data.authMenus.executeOpenRoom"
                >执行开台</el-button
              >
              <el-button type="text" @click="specOperation(4, scope.row, 'box')" v-if="data.authMenus.executeCloseRoom"
                >执行关台</el-button
              >
              <el-button type="text" @click="specOperation(5, scope.row, 'box')">刷新</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-table-column>
    <el-table-column type="selection" width="55" />
    <el-table-column type="index" label="序号" align="center" width="100" />
    <el-table-column show-overflow-tooltip prop="storeCode" label="门店编码" align="center" />
    <el-table-column show-overflow-tooltip prop="ktvId" label="KTVID" align="center" />
    <el-table-column show-overflow-tooltip prop="storeName" label="门店名称" align="center" />
    <el-table-column show-overflow-tooltip label="收银系统状态" align="center">
      <template #default="scope">
        <el-tag v-if="scope.row.storeStatus === 1" type="success">在线</el-tag>
        <el-tag v-if="scope.row.storeStatus === 2" type="waring">未知</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="400" align="center" fixed="right">
      <template #default="scope">
        <el-button type="text" @click="specOperation(1, scope.row, 'store')" v-if="data.authMenus.uploadState"
          >上报状态</el-button
        >
        <el-button type="text" @click="specOperation(2, scope.row, 'store')" v-if="data.authMenus.syncRoom"
          >同步房台</el-button
        >
        <!-- <el-button type="text" @click="specOperation(3, scope.row, 'store')" v-if="data.authMenus.executeOpenRoom"
          >执行开台</el-button
        >
        <el-button type="text" @click="specOperation(4, scope.row, 'store')" v-if="data.authMenus.executeCloseRoom"
          >执行关台</el-button
        > -->
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script setup>
import commonApi from '@/api'
import { useRoute } from 'vue-router'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import API from '../../service/index'
import { reactive, onMounted, ref, nextTick } from 'vue'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
import clearSelect from '@/lin/util/clearSelect2.js'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'children',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  //获取门店区域
  const getTreeData = data => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children.length < 1) {
        // children若为空数组，则将children设为undefined
        data[i].children = undefined
      } else {
        // children若不为空数组，则继续 递归调用 本方法
        getTreeData(data[i].children)
      }
    }
    return data
  }
  commonApi.getNewArea().then(res => {
    areaOptions.value = getTreeData(res.data)
  })
}
const casRef = ref()
const changeArea = () => {
  data.storeCode = []
  data.areaCode = data.regionCode.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}

//导出
const exportTable = async () => {
  data.loading = true
  data.loadingText = '正在火速下载数据...'
  let queryParams = {
    categoryCode: data.categoryCode, //分类
    // channel: data.channel, //通道
    spuName: data.spuName, //商品名称
    skuName: data.skuName,
    storeCode: data.storeCode,
    current: data.currentPage,
    pageSize: data.pageSize,
    remarks: data.remarks,
    regionCode: data.areaCode,
    isSales: data.isSales,
  }
  if (data.isShelves !== -1) {
    queryParams.isShelves = data.isShelves
  }
  if (data.isSupply !== -1) {
    queryParams.isSupply = data.isSupply
  }
  let url = '/adminChain/goods/saas/outputBatchProductFile'
  const res = await API.exportReport({ url, data: queryParams })
  data.loading = false
  const reader = new FileReader()
  reader.readAsText(res.data, 'utf-8')
  reader.onload = function() {
    try {
      const t = JSON.parse(reader.result) // 这里就得到了json
      ElMessage({
        message: t.msg,
        type: 'error',
      })
    } catch (error) {
      downLoad(res, '单品列表.xlsx')
    }
  }
}

const data = reactive({
  authMenus: {},
  productTypes: [],
  time: '',
  roomName: '',
  roomStatus: '',
  storeStatus: '',
  storeCode: [],
  regionCode: [],
  areaCode: [],
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  shops: [],
  loading: false,
  loadingText: '正在火速加载数据...',
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists()
  getShops()
  getAreas()
})

const getShops = () => {
  let params = {}
  if (data.areaCode.length > 0) {
    params.withInOrgans = data.areaCode
  }
  commonApi.getNewShop(params).then(res => {
    if (res.code === '200') {
      data.shops = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const reset = () => {
  data.storeStatus = undefined
  data.roomName = undefined
  data.roomStatus = undefined
  data.storeCode = []
  data.regionCode = []
  data.areaCode = []
  data.time = ''
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
  searchLists()
}

//  查询列表
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  data.loadingText = '正在火速加载数据...'
  let queryParams = {
    roomName: data.roomName,
    roomStatus: data.roomStatus,
    storeStatus: data.storeStatus,
    storeCode: data.storeCode,
    current: data.currentPage,
    pageSize: data.pageSize,
    regionCode: data.areaCode,
  }

  if (!data.time) {
    queryParams.startTime = ''
    queryParams.endTime = ''
  } else {
    queryParams.startTime = data.time[0]
    queryParams.endTime = data.time[1]
  }
  API.queryBoxManagerListPage(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data.records
        data.total = res.data.total
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

const expands = ref([])
//操作 type 1: 上报状态 2：同步房台信息 3：执行开台 4：执行关台
const specOperation = async (type, data, tag) => {
  let param = {
    storeCode: data.storeCode || '',
    roomMacKey: data.roomMacKey || '',
    roomName: data.roomName || '',
    roomCode: data.roomCode || '',
  }
  let ret = {}
  switch (type) {
    case 1:
      ret = await API.syncReportStatus(param)
      break
    case 2:
      if (tag == 'box') {
        ElMessageBox.prompt('请输入包厢号', '同步房台', {
          confirmButtonText: '确定',
          inputPlaceholder: `${data.roomName}`,
          inputValue: `${data.roomName}`,
          cancelButtonText: '取消',
        }).then(async ({ value }) => {
          if (!value) {
            ElMessage.warning('请输入包厢号')
            return
          }
          param.roomName = value

          ret = await API.syncStoreRoomMacAndIp(param)
        })
      } else {
        ret = await API.syncStoreRoomMacAndIp(param)
      }

      break
    case 3:
      ret = await API.syncStoreRoomOpen(param)
      break
    case 4:
      ret = await API.syncStoreRoomClose(param)
      break
    case 5:
      searchLists()
      expands.value = [data.storeCode]
      break
  }
  if (Object.keys(ret).length == 0) {
    return
  }
  if (ret.code != 200) {
    ElMessage.warning(ret.msg)
  } else {
    ElMessage.success('操作成功')
  }
}

const handleShowError = data => {
  ElNotification({
    title: '错误信息',
    message: data,
  })
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}

const updateAddParentProductVisible = ({ update, search }) => {
  data.addParentProductVisible = update
  if (search) {
    queryLists()
  }
}
const updateAddProductSpecVisible = ({ update, search }) => {
  data.addProductSpecVisible = update
  if (search) {
    queryLists()
  }
}
const batchDeliveryVisible = ({ update, search }) => {
  data.batchDeliveryVisible = update
  if (search) {
    queryLists()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-form-item__content {
  display: flex;
}
.product-list-top {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}

.search-select {
  width: 230px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
